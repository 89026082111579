<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-card>
        <v-layout row wrap pa-3>
          <v-flex lg12 md12 sm12 xs12>
            <v-text-field
              label="Tên cấp"
              v-model="currentLevel.VIPName"
              class="input-group--focused"
              :rules="requireRules"
            ></v-text-field>
          </v-flex>
          <v-flex lg12 md12 sm12 xs12>
            <v-text-field
              label="Thứ tự"
              v-model="currentLevel.VipValue"
              type="number"
              class="input-group--focused"
            ></v-text-field>
          </v-flex>
          <v-flex lg12 md12 sm12 xs12>
            <v-text-field
              label="Mô tả"
              v-model="currentLevel.VIPContent"
              class="input-group--focused"
            ></v-text-field>
          </v-flex>
          <v-flex lg6 md6 sm12>
            <h-currency-input v-model="currentLevel.FromMoney" :decimal="0" label="Tổng chi tiêu (Min)" class="input-group--focused"></h-currency-input>
          </v-flex>
          <v-flex lg6 md6 sm12>
            <h-currency-input v-model="currentLevel.ToMoney" :decimal="0" label="Tổng chi tiêu (Max)" class="input-group--focused" :rules="requireRules"></h-currency-input>
          </v-flex>
          <v-flex lg4 md4 sm12>
            <h-currency-input v-model="currentLevel.BuyDiscount" :decimal="0" label="Triết khấu mua hàng (%)" class="input-group--focused"></h-currency-input>
          </v-flex>
          <v-flex lg4 md4 sm12>
            <h-currency-input v-model="currentLevel.WeightDiscount" :decimal="0" label="Triết khấu cân nặng (%)" class="input-group--focused"></h-currency-input>
          </v-flex>
          <v-flex lg4 md4 sm12>
            <h-currency-input v-model="currentLevel.DepositMin" :decimal="0" label="Đặt cọc tối thiểu (%)" class="input-group--focused"></h-currency-input>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import configs from "@/configs";
export default {
  data() {
    return {
      requireRules: [v => !!v || "Trường dữ liệu không được để trống."]
    };
  },
  computed: {
    ...mapState("customerLevel", {
      loading: "loading",
      currentLevel: "selected"
    }),
    levelId() {
      return this.$route.params.Pid;
    }
  },
  methods: {
    refreshData() {
      if (this.levelId > 0) {
        this.$store.dispatch("customerLevel/getCustomerLevelDetail", this.levelId);
      }
    },
    saveData() {
      this.$store.dispatch("customerLevel/setCustomerLevelDetail", {
        data: this.currentLevel,
        id: this.levelId
      });
      window.getApp.$emit("APP_NAVIGATE", "CustomerLevel");
    }
  },
  mounted() {
    this.refreshData();
    this.$root.$emit('REQUEST_ROOT_OPTION', {});
    this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
  }
};
</script>

<style>
</style>
